<template>
  <!-- 员工中心 -->
  <AppBar>
    <div class="body_box">
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="rows" style="margin-top: 20px;">
        <!-- <el-table-column type="selection" width="55" align="center" /> -->
        <el-table-column label="角色名称" align="center" prop="name" />
        <el-table-column label="创建信息" align="center" prop="createInfo">
          <template slot-scope="scope">
            [{{ scope.row.creator }}]{{ scope.row.creatorId }}{{ scope.row.created }}
          </template>
        </el-table-column>
        <el-table-column
          label="最后操作信息"
          align="center"
          prop="lastModifyInfo"
        >
          <template slot-scope="scope">
            [{{ scope.row.lastModifier }}]{{ scope.row.lastModifierId }}{{ scope.row.lastModified }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-button
              plain
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="edit(scope.row)"
              >修改</el-button
            >
            <el-button
              plain
              type="text"
              icon="el-icon-edit-outline"
              size="mini"
              @click="goJurisdiction(scope.row)"
              >修改权限</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 修改弹窗 -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="open"
      width="500px"
      append-to-body
    >
      <el-form ref="roleAddParams" :model="roleAddParams" label-width="80px">
        <el-form-item label="角色名称" prop="name">
          <el-input placeholder="请输入名称" v-model="roleAddParams.name" />
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
  </AppBar>
</template>

<style lang="less" scoped>

.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: white;
  > i {
    width: 360px;
  }
  .box {
    position: relative;
    width: 360px;
    height: 170px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    .main {
      position: relative;
      width: 86%;
      height: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .one {
        position: relative;
        height: 100%;
        width: 36%;

        .el-image {
          height: 100%;
          width: 100%;
          border-radius: 4px;
        }
      }
      .two {
        margin-left: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        > p {
          color: #afafaf;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 120px;

          > span {
            color: #696c70;
          }
        }
      }
    }
  }
}
</style>

<script>
import AppBar from "@/components/AppBar";
import Api from "@/api/index";
export default {
  data() {
    return {
      open: false,
      currentPage: 1,
      total: 0,
      rows: [],
      // queryParams: {
      //   pageNum: 1,
      //   pageSize: 10
      // },
      roleAddParams: {
        name: ""
      },
      loginMsg: JSON.parse(sessionStorage.getItem("loginMsg")),
      editFlag: false
    };
  },
  methods: {
    // 跳到权限控制模块
    goJurisdiction(item) {
      this.$router.push({
        path: "/role/jurisdiction",
        query: {
          uuid: item.uuid
        }
      });
    },
    // 取消新建角色
    cancel() {
      this.open = false;
    },
    // 新建修改角色
    submitForm() {
      if (this.roleAddParams.name == "") {
        this.$message.error("请填写角色名称");
        return false;
      }
      let params = {
        created: this.roleAddParams.created || this.getSelfTime(new Date()),
        creatorId: this.roleAddParams.creatorId || this.loginMsg.user_code,
        creator: this.roleAddParams.creator || this.loginMsg.user_name,
        lastModifierId: this.loginMsg.user_code,
        lastModifier: this.loginMsg.user_name,
        lastModified: this.getSelfTime(new Date()),
        version: parseInt(this.roleAddParams.version) >= 0 ? parseInt(this.roleAddParams.version) : 0
      };
      params = { ...this.roleAddParams, ...params };
      Api.saveRole(params, this.editFlag)
        .then(res => {
          if (res.data.success) {
            this.getRoleAll();
            this.$message.success( this.editFlag? "修改角色成功" : "新建角色成功" );
            this.open = false;
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(err => {
          this.$message.error(err.message);
        });
    },
    // 处理时间
    getSelfTime(date) {
      let y = date.getFullYear();
      let M = date.getMonth() + 1;
      let d = date.getDate();
      let h = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      return y + '-' + (M >= 10? M : '0' + M) + '-' + (d >= 10? d : '0' + d) + ' ' + (h >= 10? h : '0' + h) + ':' + (m >= 10? m : '0' + m) + ':' + (s >= 10? s : '0' + s);
    },
    // 点击修改
    edit(item) {
      this.open = true;
      this.editFlag = true;
      this.roleAddParams = Object.assign({}, item);
    },
    // handleQuery() {
    //   this.queryParams.pageNum = 1;
    // },
    // 打开新增弹窗
    handleAdd() {
      this.open = true;
      this.editFlag = false;
      this.roleAddParams = {
        name: ""
      }
    },
    // 获取列表数据
    getRoleAll() {
      Api.getRoleAll().then(res => {
        if (res.data.success) {
          this.rows = res.data.data;
        }
      });
    }
  },
  mounted() {
    // 初始化获取数据
    this.getRoleAll();
  },
  components: {
    AppBar
  }
};
</script>
